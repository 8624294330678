<template>
  <div>
    <spinner v-if="loading" color="black" />
    <template v-else>
      <entity-card
        :entity="entity"
        :fields="fields"
        big-heading
      >
        <template #totalRevenue>
          <ul>
            <li v-for="(price, currency) in entity.totalRevenue" :key="currency">
              {{ formatPrice(price) }}
            </li>
          </ul>
        </template>
        <template #totalTip>
          <ul>
            <li v-for="(price, currency) in entity.totalTip" :key="currency">
              {{ formatPrice(price) }}
            </li>
          </ul>
        </template>
        <template #totalVat>
          <ul>
            <li v-for="(data, vat) in entity.totalVat" :key="vat">
              {{ parseFloat(vat) * 100 }}%
              <ul>
                <li v-for="(price, currency) in data" :key="currency">
                  {{ formatPrice(price) }}
                </li>
              </ul>
            </li>
          </ul>
        </template>
      </entity-card>
      <entity-table
        v-model:sortBy="orderSortBy"
        v-model:sortDirection="orderSortDirection"
        :fields="orderDisplayFields"
        :entities="orderEntities"
        :loading="loadingOrderEntities"
        :current-page="currentOrderPage"
        :total-items="totalOrderItems"
        :filters="orderFilters"
        :items-per-page="ordersPerPage"
        @update:filters="updateOrderFilters"
        @update:page="currentOrderPage = $event; loadOrderEntities()"
        @update:sort="loadOrderEntities()"
      />

      <router-view />
    </template>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import axios from '@/services/axios';
import EntityCard from '@/components/shared/EntityCard.vue';
import EntityTable from '@/components/shared/EntityTable/EntityTable.vue';
import Spinner from '@/components/utils/spinner.vue';
import { formatDate } from '@/utils/date-utils';
import formatPrice from '@/utils/formatPrice';
import useLoadEntities from '@/hooks/api/loadEntities';
import getIdFromIri from '@/utils/getIdFromIri';

export default {
  name: 'ShiftDetailPage',
  components: { EntityCard, EntityTable, Spinner },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const { t } = useI18n();
    const loading = ref(false);
    const entity = ref(null);
    const fields = ref([
      { key: 'register.name', label: t('register.label') },
      { key: 'shift.start', label: t('general.field.start'), formatter: (v) => formatDate(v, 'dd.MM.yyyy HH:mm:ss') },
      { key: 'shift.end', label: t('general.field.end'), formatter: (v) => formatDate(v, 'dd.MM.yyyy HH:mm:ss') },
      { key: 'numberOfOrders', label: t('shift.numberOfOrders') },
      { key: 'totalRevenue', label: t('shift.totalRevenue') },
      { key: 'totalTip', label: t('shift.totalTip') },
      { key: 'totalVat', label: t('shift.totalVat') },
    ]);

    const orderDisplayFields = ref([
      { key: 'orderTime', label: t('order.orderTime'), displayFilter: (v) => formatDate(v, 'dd.MM.yyyy HH:mm:ss') },
      { key: 'total', label: t('order.total'), displayFilter: (v) => formatPrice(v) },
      { key: 'invoiceNumber', label: t('order.invoiceNumber') },
      { key: 'orderCalloutNumber', label: t('order.orderCalloutNumber') },
      {
        key: '_actions',
        actions: ['detail'],
        actionOptions: (e) => ({
          detail: router.resolve({ name: 'store-shifts-orders-order-id', params: { orderId: getIdFromIri(e['@id']) } }),
        }),
      },
    ]);

    const orderApiFields = ref([
      { key: 'shift', searchFilter: { type: 'freeText', default: route.params.shiftId } },
    ]);

    const ordersPerPage = 10;

    const {
      entities: orderEntities,
      filters: orderFilters,
      loadingEntities: loadingOrderEntities,
      currentPage: currentOrderPage,
      totalItems: totalOrderItems,
      sortBy: orderSortBy,
      sortDirection: orderSortDirection,
      loadEntities: loadOrderEntities,
      updateFilters: updateOrderFilters,
      resetFilters: resetOrderFilters,
    } = useLoadEntities('/orders', {
      fields: orderApiFields, sort: 'orderTime', itemsPerPage: ordersPerPage, hideDeleted: false,
    });

    const loadData = async () => {
      loading.value = true;

      try {
        const { data: shiftStatistics } = await axios.get(`/shifts/${route.params.shiftId}/statistics`);
        entity.value = shiftStatistics;
      } catch (err) {
        console.error(err, err.message, err.response);
      }

      loading.value = false;
    };

    loadData();

    return {
      loading,
      entity,
      fields,
      loadData,
      orderDisplayFields,
      orderFilters,
      orderEntities,
      currentOrderPage,
      loadingOrderEntities,
      totalOrderItems,
      orderSortBy,
      orderSortDirection,
      loadOrderEntities,
      updateOrderFilters,
      resetOrderFilters,
      ordersPerPage,
    };
  },
  methods: {
    formatPrice,
  },
};
</script>
