<template>
  <div>
    <spinner v-if="loading" color="#000" />
    <template v-else>
      <entity-card
        :entity="entity"
        :title="entity.name"
        :fields="fields"
        big-heading
      >
        <template #action>
          <div class="entity-detail-page-actions">
            <button
              class="small white mr-2"
              @click="openDeleteModal(entity)"
              v-text="t('general.action.delete')"
            />
            <button
              class="small"
              @click="$store.dispatch('modals/openModal', { modal: 'editRegisterTheme', entity })"
              v-text="t('general.action.edit')"
            />
          </div>
        </template>
        <template v-for="color in REGISTER_THEME_COLORS" #[color] :key="color">
          <div
            class="square"
            :style="{'background-color': 'rgba(' + (entity[color]?.red ?? 0) + ', ' + (entity[color]?.green ?? 0) + ',' +
              (entity[color]?.blue ?? 0) + ',' + (entity[color]?.alpha ?? 1) + ')'}"
          />
        </template>
      </entity-card>

      <edit-register-theme-modal @update="loadData" />
      <delete-modal
        type="deleteTheme"
        entity-translation-key="registerTheme"
        @update="$router.push('/themes')"
      />
    </template>
  </div>
</template>

<script>
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { ref } from 'vue';
import axios from '@/services/axios';
import Spinner from '@/components/utils/spinner.vue';
import EntityCard from '@/components/shared/EntityCard.vue';
import EditRegisterThemeModal from '@/components/entities/register_theme/EditRegisterThemeModal.vue';
import DeleteModal from '@/components/shared/DeleteModal.vue';
import { REGISTER_THEME_COLORS } from '@/constants';

export default {
  name: 'RegisterThemeDetailPage',
  components: {
    EditRegisterThemeModal,
    DeleteModal,
    Spinner,
    EntityCard,
  },
  setup() {
    const route = useRoute();
    const { t } = useI18n();
    const loading = ref(true);
    const entity = ref(null);
    const identifiers = ref([]);

    const fields = ref([
      { key: 'name', label: t('general.field.name'), colspan: true },
      ...REGISTER_THEME_COLORS.map((color) => ({ key: color, label: t(`registerTheme.color.${color}`) })),
    ]);

    const loadData = async () => {
      loading.value = true;

      try {
        const { data } = await axios.get(`/register_themes/${route.params.id}`);
        entity.value = data;
      } catch (err) {
        console.error(err, err.message, err.response);
      }

      loading.value = false;
    };

    const store = useStore();

    const openDeleteModal = (e) => {
      store.dispatch('modals/openModal', { entity: e, modal: 'deleteTheme' });
    };

    loadData();

    return {
      t,
      entity,
      identifiers,
      fields,
      loading,
      loadData,
      openDeleteModal,
      REGISTER_THEME_COLORS,
    };
  },
};
</script>

<style lang="scss" scoped>
.square {
  @apply border border-black;

  width: 4rem;
  height: 4rem;
}
</style>
