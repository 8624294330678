<template>
  <modal-form
    ref="modal"
    v-slot="{ properties, errors }"
    type="editRegisterTheme"
    :normalizer="normalizer"
    :options="{
      properties: props,
      postUri: '/register_themes',
      label: $t('registerTheme.label'),
    }"
  >
    <fancy-input v-model="properties.name" :error="errors.name" :label="$t('general.field.name')" />

    <theme-color-form
      v-for="color in REGISTER_THEME_COLORS"
      :key="color"
      v-model="properties[color]"
      :error="errors[color]"
      :label="$t(`registerTheme.color.${color}`)"
    />

  </modal-form>
</template>

<script>
import { ref } from 'vue';
import ModalForm from '@/components/shared/ModalForm.vue';
import FancyInput from '@/components/utils/forms/FancyInput.vue';
import ThemeColorForm from '@/components/shared/forms/ThemeColorForm.vue';
import { REGISTER_THEME_COLORS } from '@/constants';

export default {
  name: 'EditRegisterThemeModal',
  components: {
    ThemeColorForm,
    FancyInput,
    ModalForm,
  },
  setup() {
    const modal = ref(null);

    const props = { name: '' };

    REGISTER_THEME_COLORS.forEach((color) => {
      props[color] = {
        red: 0,
        green: 0,
        blue: 0,
        alpha: 1.0,
      };
    });

    const normalizer = (entity) => {
      const normalized = {
        ...entity,
      };

      Object.keys(normalized).forEach((prop) => {
        if (prop.startsWith('color') && normalized[prop]?.['@id'] != null) {
          normalized[prop] = {
            ...normalized[prop],
            id: normalized[prop]['@id'],
            '@id': undefined,
          };
        }
      });

      return normalized;
    };

    return {
      modal,
      normalizer,
      REGISTER_THEME_COLORS,
      props,
    };
  },
};
</script>
