<template>
  <div class="grid grid-cols-3 gap-4">
    <fancy-input
      v-model="model"
      :label="label"
      type="color"
      :error="error"
      required
    />
    <fancy-input
      v-model="alpha"
      :label="$t('theme.color.alpha')"
      type="number"
      min="0"
      max="1"
      step="0.01"
      required
    />
    <div>
      <label>
        {{ label }}
        {{ $t('theme.color.withAlpha') }}
      </label>
      <div class="h-10 rounded border border-black model-color" />
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import FancyInput from '@/components/utils/forms/FancyInput.vue';

export default {
  name: 'ThemeColorForm',
  components: { FancyInput },
  props: {
    modelValue: { type: Object, required: true },
    label: { type: String, default: null },
    required: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
    error: { type: String, default: null },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const toHex = (c) => {
      const hex = c.toString(16);
      return hex.length === 1 ? `0${hex}` : hex;
    };

    const model = computed({
      get: () => `#${toHex(props.modelValue?.red ?? 0)}${toHex(props.modelValue?.green ?? 0)}${toHex(props.modelValue?.blue ?? 0)}`,
      set: (val) => {
        const red = parseInt(val.substring(1, 3), 16);
        const green = parseInt(val.substring(3, 5), 16);
        const blue = parseInt(val.substring(5), 16);
        emit('update:modelValue', {
          ...props.modelValue,
          red,
          green,
          blue,
        });
      },
    });

    const alpha = computed({
      get: () => props.modelValue?.alpha ?? 1,
      set: (val) => emit('update:modelValue', {
        ...props.modelValue,
        alpha: val,
      }),
    });

    const rgbaColor = computed(
      () => `rgba(${props.modelValue?.red ?? 0},${props.modelValue?.green ?? 0},${props.modelValue?.blue ?? 0},${props.modelValue?.alpha ?? 1}`,
    );

    return {
      model,
      alpha,
      rgbaColor,
    };
  },
};
</script>

<style lang="scss" scoped>
.model-color {
  background-color: v-bind(rgbaColor);
}
</style>
